<template>
  <div class="flex flex-col bg-white">
    <div class="flex items-center mb-4 mobile:flex-col">
      <h2 class="mr-2 mobile:mr-0 mobile:mb-4">Pakkumised</h2>
      <div
        class="items-center flex flex-row cursor-pointer relative hover:bg-offwhite mobile:mb-4"
        @click="statusMenuActive = !statusMenuActive"
        :class="statusMenuActive ? 'rounded-t-xl' : 'rounded-xl'"
      >
        <span v-if="status" v-html="parseDraftOfferStatus(status)" />
        <span v-if="!status" class="pl-4 pr-8 py-2"
          >Filtreeri staatuse järgi</span
        >
        <span class="typcn typcn-arrow-sorted-down mx-3"></span>
        <div class="status-menu mobile:z-10" v-if="statusMenuActive">
          <span
            v-for="statusItem in statusFilters"
            :key="statusItem.status"
            class="status-menu-item cursor-pointer"
            v-html="parseDraftOfferStatus(statusItem.status)"
            @click="filterOffers(statusItem.status)"
          ></span>
        </div>
      </div>

      <div class="ml-2 mobile:ml-0 mobile:mb-6">
        <button class="new-button-danger" v-if="status" @click="clearFilter">
          <span class="icon"
            ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
          /></span>
          <span class="label">Tühjenda</span>
        </button>
      </div>
    </div>

    <div class="flex flex-row mobile:flex-col">
      <div
        v-if="offersLoaded"
        class="flex w-full flex-col pr-5 mobile:pr-0 mobile:order-2"
      >
        <span class="" v-if="draftWithOffers.length === 0">Pakkumisi pole</span>
        <draft
          v-for="draft in draftWithOffers"
          :key="draft.uuid"
          :draft="draft"
          :status="status"
        ></draft>
      </div>
    </div>
    <add-invoice-modal
      :offer="$store.state.invoiceViewer.invoiceOffer"
      :draft="$store.state.invoiceViewer.relatedDraft"
      v-if="$store.state.invoiceViewer.displayAddInvoiceModal"
    ></add-invoice-modal>
  </div>
</template>

<script>
import Draft from "./offers/Draft";
import RequestHandler from "@/assets/mixins/RequestHandler";
import StatusParser from "@/assets/mixins/StatusParser";
import AddInvoiceModal from "@/components/project/invoice/modal/AddInvoiceModal";
import EventBus from "@/assets/mixins/EventBus";
const statusFilters = [
  {
    text: "Aktiivne",
    status: 1
  },
  {
    text: "Käigus",
    status: 2
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  }
];

export default {
  name: "CompanyOffers",
  components: { Draft, AddInvoiceModal },
  mixins: [RequestHandler, StatusParser],
  data() {
    return {
      offersLoaded: false,
      draftWithOffers: [],
      allOffers: [],
      statusFilters: statusFilters,
      status: 0,
      statusMenuActive: false
    };
  },
  mounted() {
    this.retrieveCompanyOffers();
    EventBus.$on("updateOffers", () => {
      this.retrieveCompanyOffers();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateOffers");
  },
  methods: {
    retrieveCompanyOffers() {
      this.apiRequest(
        "notes/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/offers/",
        "get",
        true
      ).then(res => {
        this.draftWithOffers = res.data;
        this.allOffers = res.data;
        this.offersLoaded = true;
        if (this.status > 0) {
          this.filterOffers(this.status);
        }
        this.updateModal();
      });
    },
    updateModal() {
      if (
        this.$store.state.offerViewer.offerModal.show &&
        this.$store.state.offerViewer.offerModal.data
      ) {
        let offers = [];
        this.allOffers.forEach(x => {
          x.offers.forEach(y => (y.draft = x.uuid));
          offers = offers.concat(x.offers);
        });
        let offer = offers.find(
          x => x.id === this.$store.state.offerViewer.offerModal.data.offer.id
        );
        this.$store.dispatch("offerViewer/openOfferModal", {
          offer: offer,
          draft: this.$store.state.offerViewer.offerModal.data.draft
        });
      }
    },
    clearFilter() {
      this.status = 0;
      this.draftWithOffers = this.allOffers;
    },
    filterOffers(filterStatus) {
      this.offersLoaded = false;
      this.draftWithOffers = [];
      this.status = filterStatus;
      for (let i = 0; i < this.allOffers.length; i++) {
        if (
          this.allOffers[i].offers.filter(x => x.status === filterStatus)
            .length > 0
        ) {
          if (
            this.draftWithOffers.find(
              y => y.uuid === this.allOffers[i].uuid
            ) === undefined
          ) {
            this.draftWithOffers.push(this.allOffers[i]);
          }
        }
      }
      this.offersLoaded = true;
    },

    addInvoice(e) {
      this.$store.dispatch("invoiceViewer/openAddInvoiceModal", e);
    }
  }
};
</script>

<style scoped lang="scss">
.co-filter-button {
  @apply flex items-center justify-center cursor-pointer duration-100 rounded-xl shadow bg-offwhite text-darkgrey w-full p-2 mb-2;
  &.active {
    @apply bg-green cursor-default shadow text-white;
  }
  &:hover {
    @apply bg-darkgreen cursor-pointer shadow text-white;
  }
}
</style>
