<template>
  <div
    class="flex bg-white rounded-gwdhalf items-center flex-col cursor-pointer duration-100"
    @click="openOfferModal"
  >
    <div
      class="flex w-full justify-center mobile:justify-between items-center top-row py-3 hover:bg-offwhite hover:bg-opacity-50 px-3 mobile:flex-wrap"
    >
      <div class="w-3/12 flex justify-start font-medium mobile:w-6/12">
        <span>Pakkumine #{{ offer.offer_index }}</span>
      </div>
      <div class="w-3/12 mobile:justify-end flex items-center mobile:w-6/12">
        <span
          class="bg-green font-semibold text-white rounded-gwdfull shadow w-1 h-8 justify-center flex text-xl mr-2"
        ></span>
        <span class="font-medium">{{ offer.price }}€</span>
      </div>
      <div class="w-3/12 flex justify-start mobile:w-4/12">
        {{ moment(offer.created_at).format("DD.MM.YYYY") }}
      </div>
      <div class="flex relative w-3/12 mobile:justify-end mobile:w-8/12">
        <div
          v-if="
            $store.state.companyData.activeCompany.worker_permission !== 'R6'
          "
          :class="statusMenuOpen ? 'rounded-t-xl shadow' : 'rounded-xl'"
          v-html="parseDraftOfferStatus(offer.status)"
        ></div>
      </div>
    </div>
    <div class="w-full h-1 rounded-full bg-offwhite"></div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
import RequestHandler from "@/assets/mixins/RequestHandler";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
export default {
  name: "Offer",
  props: {
    offer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    activeOfferId: {
      type: Number,
      default: null
    }
  },
  components: {},
  mixins: [StatusParser, RequestHandler],
  mounted() {
    this.tempData = cloneDeep(this.offer.items);
    this.details = this.offer.details;
  },
  methods: {
    changeOfferStatus(status) {
      if (status !== this.offer.status) {
        this.apiRequest(
          "notes/draft/" +
            this.draft.uuid +
            "/offer/" +
            this.offer.id +
            "/edit/",
          "patch",
          true,
          { status: status }
        ).then(res => {
          if (res) {
            this.$emit("offersUpdated");
            this.statusMenuOpen = false;
          }
        });
      } else {
        this.statusMenuOpen = false;
      }
    },
    openOfferModal() {
      this.$store.dispatch("offerViewer/openOfferModal", {
        offer: this.offer,
        draft: this.draft
      });
    }
  },
  data() {
    return {
      showServices: false,
      showEmailForm: false,
      tempData: null,
      newServices: [],
      moment: moment,
      statusMenuOpen: false
    };
  }
};
</script>
