<template>
  <offers></offers>
</template>

<script>
import Offers from "@/components/company/Offers";
export default {
  components: {
    Offers
  }
};
</script>
